export enum LOCAL_STORAGE_KEYS {
    TOKEN = 'TOKEN'
};

export enum RESPONSE_CODES {
    SUCCESS = 1000,
    ERROR = 1305,
    UNAUTHORIZED = 401,
    //backend not confirmed about all the response codes
};

export enum QUICK_CONTACT_TYPE {
    AMBULANCE = "Ambulance",
    FIRE_BRIGADE = "Fire Brigade",
    HOSPITAL = "Hospital",
    POLICE = "Police",
    SOCIETY = "Society",
};

export enum FILE_TYPES {
    PDF = 'pdf',
    JPG = 'jpg',
    JPEG = 'jpeg',
    AVIF = 'avif',
    PNG = 'png'
}

export enum KYC_DOCUMENT_TYPE {
    AADHAAR = 'AADHAAR',
    PASSPORT = 'PASSPORT',
    PAN_CARD = 'PAN_CARD',
    VOTER_ID = 'VOTER_ID',
    DRIVING_LISCENSE = 'DRIVING_LISCENSE'
}

export enum SHIFT_NAMES {
    SHIFT_A = 'Shift A',
    SHIFT_B = 'Shift B',
    SHIFT_C = 'Shift C',
    GENERAL_SHIFT = 'General Shift'
}

export enum PERMISSIONS {
    SECURE_APP_ADMIN_ACCESS = 'Secure App Admin Access',
    SECURE_APP_GUARD_ACCESS = 'Secure App Guard Access',
    MANAGE_WEB_ADMIN_ACCESS = 'Manage Web Admin Access'
}

export enum VEHICLE_TYPES {
    NONE = "NONE",
    BIKE = "BIKE",
    AUTO = "AUTO",
    CAR = "CAR",
    HEAVY = "HEAVY"
}

export enum GENDER {
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export enum PAYMENT_ACCOUNT_TYPE {
    NONE = "None",
    UPI = "UPI",
    BANK_ACCOUNT = "Bank Account"
}

export enum DAYS {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday'
}

export enum DEVICE_TYPES {
    SOCIETY = 'SOCIETY',
    PERSONAL = 'PERSONAL'
}

export enum DEVICE_STATUS {
    LOCKED = 'LOCKED',
    UNLOCKED = 'UNLCOKED'
}

export enum CHECKPOINT_STATUS {
    YET_TO_SCAN = 'Yet To Scan',
    SCANNED = 'Scanned',
    SKIPPED = 'Location Skipped',
    LOCATION_MISMATCH = 'Location Mismatch'
}

export enum PATROL_LOG_STATUS {
    UPCOMING = 'Upcoming',
    START = 'Start',
    RESUME = 'Resume',
    IN_PROGRESS = 'In Progress',
    EXPIRED = 'Expired',
    COMPLETED = 'Completed',
    TIMEOUT = 'Time Out'
}

export enum FACING {
    EAST = 'East',
    WEST = 'West',
    NORTH = "North",
    SOUTH = "South",
    NORTH_EAST = "North East",
    SOUTH_EAST = "South East",
    NORTH_WEST = "North West",
    SOUTH_WEST = "South West"
}

export enum FLAT_SHARE {
    BUILDER = "Builder",
    LANDLORD = "Landlord"
}

export enum FLAT_STATUS {
    INACTIVE = "Inactive",
    HANDOVER = "Handover",
    EMPTY = "Empty",
    OWNER = "Owner",
    TENANT = "Tenant"
}

export enum CUSTOMER_TYPE {
    ORG = 'ORG',
    INDIVIDUAL = 'INDIVIDUAL',
    POC = 'POC',
    GPA = 'GPA',
    DRAFT = 'DRAFT',
}

export enum CITIZENSHIP_STATUS {
    INDIAN = 'Indian',
    NRI = 'NRI',
    FOREIGNER = 'Foreign Nationals'
}


export enum ORG_TYPE {
    REGISTERED_PARTNERSHIP = 'registered partnership',
    LIMITED_LIABILITY_PARTNERSHIP = 'limited liability partnership',
    COMPANY = 'company'
}

export enum ORG_RELATION {
    BOARD = 'board',
    PARTNERS = 'partners'
}

export enum ORG_IDENTITY_TYPE {
    SL_NO = 'slNo',
    LLP_IN = 'llpIn',
    CIN = 'cin'
}

export enum STARRED_TABS {
    DASHBOARD = 'Dashboard',
    FLATS = 'Flats',
    HELP_DESK_SETUP = 'Helpdesk Setup',
    HELP_DESK_TICKETS = 'Helpdesk Tickets',
    STAFF_DIRECTORY = 'Staff Directory',
    HELPERS = 'Helpers',
    ROLES_AND_DEPARTMENTS = 'Roles and Departments',
    DEVICES = 'Devices',
    GATES = 'Gates',
    CHECKPOINTS = 'Checkpoints',
    PATROL_SHIFTS = 'Patrol Shifts',
    PATROL_LOGS = 'Patrol Logs',
    ACCOUNTS_AND_TRANSFERS = 'Accounts and Transfers',
    HEADERS = 'Headers',
    QUICK_CONTACTS = 'Quick Contacts',
    ACCESS_LOGS = 'Access Logs',
    PARCEL_LOGS = 'Parcel Logs',
    HELPDESK_SETTINGS = 'Helpdesk Settings'
}
